<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:230px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">充值总览</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col :span="10">
                            <el-card shadow="always">
                                <el-row>
                                    <el-col :span="12">
                                        <div style="font-size: 20px;font-weight: bold;height: 110px;line-height: 50px; ">
                                            总余额比索(MXN)
                                            <div style="font-size: 50px;font-weight: bold;color: red;">{{total.value1}}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <div style="width: 96%; margin-left:2%;border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">充值信息</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col v-if="total.value2 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value2}}</span>
                                    <el-button style="float: right; padding: 10px 2px" type="text" @click="openadd(1)">充值</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value2}}</th>
                                        <th>比索 MXN</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value3}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value4}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value5}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value18 != null" :span="6">
                            <el-card shadow="hover">
                                <div slot="header" class="clearfix">
                                    <span>{{total.value18}}</span>
                                    <el-button v-if="total.value21 > 0" style="float: right; padding: 10px 2px" type="text" @click="openadd(5)">充值</el-button>
                                </div>
                                <table style="text-align: left;">
                                    <tr>
                                        <th style="width: 220px;">{{total.value18}}</th>
                                        <th>比索 MXN</th>
                                    </tr>
                                    <tr>
                                        <th>商户余额: </th>
                                        <th>{{total.value19}}</th>
                                    </tr>
                                    <tr>
                                        <th>不可用余额: </th>
                                        <th>{{total.value20}}</th>
                                    </tr>
                                    <tr>
                                        <th>可用余额: </th>
                                        <th>{{total.value21}}</th>
                                    </tr>
                                </table>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <pop-up :title="(this.cl == 1 ? total.value2 : total.value18) + '充值'" :isshow="showadd" :haveconfirm="true" 
            @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="充值金额:">
                        <el-input-number v-model="form.moeny" :min="1"/>
                    </el-form-item> 
                    <el-form-item label="充值方式:">
                        <el-select v-model="form.type" placeholder="请选择充值方式">
                            <el-option label="线上充值" value="3"></el-option>
                            <el-option label="线下充值" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="支付密码:">
                        <el-input type="password" v-model="form.paypass" />
                    </el-form-item> 
                    <el-form-item label="充值目的:">
                        <el-input type="textarea" v-model="form.comentarios"></el-input>
                    </el-form-item> 
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
                value7: 0,
                value8: 0,
                value9: 0,

                value18: 0,
                value19: 0,
                value20: 0,
                value21: 0,

            },
            form: {
                id: null,
                moeny: null,
                type: null,
                comentarios: null,
                paypass: null,
                channel: null,
                iden:2
            },
            showadd: false,
            cl: 1,
        }
    },
    created(){
        this.getTotal();
    },
    methods:{
        openadd(row) {
            this.cl = row
            this.showadd = true
            this.form = {
                id: null,
                moeny: null,
                type: null,
                comentarios: null,
                paypass: null,
                iden:2,
                channel:row,
            }
        },
        getclose() {
            this.$refs.form.resetFields();
            this.showadd = false
        },
        getconfirm() {
            if(this.form.moeny==null || this.form.moeny==""){
                this.$message.error("金额不能为空")
                return
            }
            if (this.form.paypass == null) {
                this.$message.error("支付密码不能为空")
                return
            }
            _api.post(apiurl.mencionar, this.form).then(res => {
                this.$notify.success({
                    title: this.$t('success'),
                    message: this.$t('addSuccess')
                })
                this.showadd = false
                this.form = {
                    id: null,
                    moeny: null,
                    type: null,
                    paypass: null,
                    comentarios: null,
                    iden:2
                }
                this.getTotal();
            }).catch(err => {
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                })
            })
        },
        getTotal(){
            this.loading = true
            _api.get(apiurl.withdraw,{
            }).then(res=>{
                this.loading = false
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
                this.total.value5=res.data.value5
                this.total.value6=res.data.value6
                this.total.value7=res.data.value7
                this.total.value8=res.data.value8
                this.total.value9=res.data.value9


                this.total.value18=res.data.value18
                this.total.value19=res.data.value19
                this.total.value20=res.data.value20
                this.total.value21=res.data.value21
            })
        }
    }
}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }
</style>